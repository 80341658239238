//*** Responsive breakpoints ***//
$mobile-m-bp: 425px; // below portrait
$mobile-bp: 599px; // mobile
$tablet-bp: 767px; // tablet medium
$laptop-bp: 991px; // laptop 
$desktop-bp: 1024px; // below mini desktop
$desktop-sm-bp: 1199px; // below small desktop
$desktop-md-bp: 1439px; // below middle desktop

//*** Container Max-Width ***//
$container-fr: 640px;
$container-tb: 800px;
$container-mi: 1078px;
$container-inner: 1236px;
$container-xs: 860px;
$container-sm: 1300px;
$container-md: 1440px;
$container-fhd: 1920px;

//*** Container Padding ***//
$container-p: 30px;
$mobile-padding: 20px;

//*** Colors ***//
$color-white: #FFFFFF;
$color-blue: #272560;
$color-blue-80: #525180;
$color-blue-40: #A8A8BF;
$color-blue-25: #C9C9D7;
$color-blue-10: #E9E9EF;
$color-orange: #E67C25;
$color-text: $color-blue;
$color-secondary: #3A74B4;
$color-grey: #f7f7f7;
$color-blue-overlay: #3d3b70;
$color-orange-hover: #DB5C15;

//*** Font ***//
$f-body: Graphik, Helvetica, Arial, sans-serif;
$f-heading: Graphik, Helvetica, Arial, sans-serif;

//*** Z-Index ***//
$z-header: 100000000;
$z-header-item: 101;
