//* for iphone 5s/se1
@mixin mobile-m {
  @media screen and (max-width: $mobile-m-bp) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile-bp) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-bp) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop-bp) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $desktop-bp) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: $desktop-sm-bp) {
    @content;
  }
}

@mixin desktop-l {
  @media screen and (max-width: $desktop-md-bp) {
    @content;
  }
}

@mixin desktop-infinite {
  @media screen and (max-width: 5000px) {
    @content;
  }
}

@mixin link($color: $color-orange, $underlineOnHover: true) {
  & {
    color: $color;
    text-decoration: none;
    @content;

    &:visited,
    &:active,
    &:focus {
      color: $color;
    }
  }

  @if ($underlineOnHover) {
    &:hover {
      text-decoration: underline;
    }
  }
}


@mixin imageHoverEffect {
  &:hover {
    .treaserImageBox {
      transition: 0.3s ease-in-out;

      img {
        -ms-transform: scale(1.1);
        /* IE 9 */
        -webkit-transform: scale(1.1);
        /* Safari 3-8 */
        transform: scale(1.1);
      }

      transform: translateX(-40px);

      @include tablet {
        transform: translateY(-40px);
      }
    }
  }
}

// common button style with arrow
@mixin arrowButton {
  a {
    font-size: 14px;
    color: #e67c25;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    position: relative;

    &:after {
      content: '\62';
      position: absolute;
      font-size: 20px;
      margin-left: 0px;
      transition: 0.5s;
      font-weight: bold;
      font-family: 'permira' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
