@import './utils.scss'; //*** Global Styles ***//
// setting up basic style for body, reset default styling

// Mobile-first new spacing properties
:root {
  --padding-standard: 20px;
  --section-margin-standard: 30px;

  @media screen and (min-width: 768px) {
    --padding-standard: 20px;
    --section-margin-standard: 45px;
  }

  @media screen and (min-width: 1024px) {
    --padding-standard: 30px;
    --section-margin-standard: 60px;
  }
}

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  width: 100%;
}

html {
  overflow-x: hidden;
  overflow-x: clip;
}

body {
  font-family: $f-body;
  font-size: 14px;
  line-height: 1.25;
  color: $color-text;
  overflow-x: clip;

  // fix iOS bug
  &.ReactModal__Body--open {
    @supports (-webkit-touch-callout: none) {
      position: relative !important;
    }
  }
}

#__next {
  overflow-x: unset !important;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

p {
  margin: 0;
  line-height: inherit;
}

hr {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}

a {
  text-decoration: none;
  @include link;
}

button {
  background-color: transparent;
  appearance: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

::placeholder {
  color: $color-blue-40;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.main-content {
  width: 100%;

  &.no-splash {
    padding-top: 100px;

    @include tablet {
      padding-top: 60px;
    }
  }

  @include tablet {
    padding-bottom: 0px;
  }
}

.no-splash {
  padding-top: 100px;

  @include desktop {
    padding-top: 60px;
  }
}

/* common styles */
.container {
  padding: 0 $container-p;
  width: 100%;
  margin: 0 auto;

  @include tablet {
    padding: 0 $mobile-padding;
  }
}

h1,
.h1 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.03em;

  @include tablet {
    font-size: 36px;
  }
}

h2,
.h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: -0.03em;

  @include tablet {
    font-size: 28px;
  }
}

h3,
.h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.03em;

  @include tablet {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.03em;

  @include tablet {
    font-size: 20px;
  }
}

.arrow-link {
  position: relative;
  display: inline-block;
  padding: 10px 35px 10px 0;
  margin-bottom: -10px;
  margin-top: -10px;

  span {
    display: block;
    position: absolute;
    font-size: 171%;
    right: 0;
    top: 15%;
    transition: transform 0.3s;
  }

  &:hover {
    text-decoration: none;

    span {
      transform: translateX(20px);
    }
  }
}

//* Overwrite slick slider css
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list .dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
  display: flex;
  align-items: stretch;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  overflow: hidden;
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
}

/*# sourceMappingURL=slick.min.css.map */

//reat-slick custom css of pagination
.slick-slider {
  ul {
    display: flex !important;
    justify-content: flex-end;

    li {
      font-size: 0;

    }

    .slick-point,
    .slick-active {
      display: flex;
      cursor: pointer;
      height: 12px;
      width: 12px;
      margin-top: 40px;
      margin-left: 16px;
      background-color: $color-blue;
      border-radius: 50%;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.3);
      }
    }

    .slick-active {
      background-color: $color-orange;
    }

  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.loading {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.loading:after {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  margin: 0;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: $color-text transparent $color-text transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// video
.video-js .vjs-big-play-button {
  width: 84px !important;
  height: 84px !important;
  top: 50% !important;
  left: 50% !important;
  background-color: white !important;
  border-radius: 50% !important;
  margin: -42px 0 0 -42px !important;

  .vjs-icon-placeholder:before {
    color: $color-text;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.orangeClr {
  color: $color-orange !important;
}

.ReactModal__Overlay {
  z-index: 999999999;

  .ReactModal__Content {
    padding: 0 !important;
  }
}

// page modal
.page-modal {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
  position: fixed;
  opacity: 1;
  background: white;
  transform: translate(100%, 0);
  border-left: 38px solid $color-blue;

  @include mobile {
    border-left: none;
  }

  &.ReactModal__Content--after-open {
    transition: opacity 0.6s ease-in-out;
    opacity: 1;
  }

  &.ReactModal__Content--before-close {
    transition: opacity 0.6s ease-in-out;
    opacity: 0;
  }

  &.ReactModal__Content--after-open {
    transition: translate 0.6s ease-in-out, transform 0.6s ease-in-out;
    transform: translate(0, 0);
    opacity: 1;
  }

  &.ReactModal__Content--before-close {
    transition: translate 0.6s ease-in-out, transform 0.6s ease-in-out;
    transform: translate(100%, 0);
    opacity: 1;
  }

  &.portfolio-modal .close-btn {
    top: 60px;
  }
  &.charity-modal .close-btn {
    top: 60px;
  }
  .close-btn {
    width: 30px;
    height: 30px;
    right: $container-p;
    top: 70px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
    text-align: right;
    margin-left: auto;
    margin-right: 0;

    @include mobile {
      right: $mobile-padding;
      top: 22px;
    }

    @media screen and (min-width: $container-md) {
      right: calc((100vw - $container-md) / 2 + $container-p);
    }

    &:hover {
      transform: scale(1.1);
    }

    span {
      width: 26px;
      height: 1px;
      background-color: #272560;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;

      &:first-child {
        transform: translate(-50%) rotate(45deg);
      }

      &:last-child {
        transform: translate(-50%) rotate(-45deg);
      }
    }
  }
}

.page-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100000000;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;

  &.ReactModal__Overlay--before-close {
    transition-delay: 0.4s;
    opacity: 0;
  }
}

// component spacing
.related-news {
  &:last-child {
    margin-bottom: 129px;
  }

  &+.two-cols-media {
    margin-top: 60px;

    @include mobile {
      margin-top: 40px;
    }
  }
}

.people-list {
  &:last-child {
    margin-bottom: 200px;

    @include mobile {
      margin-bottom: 120px;
    }
  }
}

.two-cols-media {
  &:last-child {
    margin-bottom: 100px;
    border-bottom: none;

    @include mobile {
      margin-bottom: 60px;
    }
  }

  &+.related-news {
    border-top: 1px solid $color-blue-25;
    margin-top: 40px;
  }
}

.inv-res-report {
  &+.inv-res-report {
    border-bottom: 1px solid $color-blue-25;
  }
}

.slick-dots.slick-bars {
  justify-content: center;

  li {
    width: 50px;
    background-color: transparent;
    margin-top: 10px;
  }

  .slick-active {
    background-color: transparent;
    margin-top: 10px;
    border-radius: 0;
    margin-left: 0;

    &:hover {
      transform: none;
    }

    .bar {
      background-color: $color-blue;
    }
  }
}

/*
FILTER STANDARDISATION STYLES
*/
html body [data-reactroot] {

  .global-filters {
    --global-filters-display: grid;

    &.desktop-only {
      @media (max-width: 767px) {
        --global-filters-display: inherit;
      }
    }

    display: var(--global-filters-display);

    // Columns: 5
    &.columncount-5 {
      grid-template-columns: 20% 20% 20% 20% 20%;

      // Children: 1 or 2
      &.childcount-1,
      &.childcount-2 {
        *:not(:last-child) {
          grid-column: 1 / 4;
        }

        *:last-child {
          grid-column: 4 / 6;

          @media (min-width: 767px) {
            grid-column: 5;
          }
        }
      }
    }

    // Columns: 4
    &.columncount-4 {
      grid-template-columns: 25% 25% 25% 25%;

      // Children: 1 or 2
      &.childcount-1,
      &.childcount-2 {
        *:not(:last-child) {
          grid-column: 1 / 3;
        }

        *:last-child {
          grid-column: 3 / 5;

          @media (min-width: 767px) {
            grid-column: 4;
          }
        }
      }
    }
  }
}

.gm-style-iw-chr {
  display: none;
}
.gm-style-iw-d,.gm-style-iw.gm-style-iw-c {
  overflow: auto !important;
  padding: 0 !important;
}
